export default {
  "aws_project_region": "us-west-2",
  "aws_cognito_identity_pool_id": "us-west-2_mObtaNpLr",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-west-2_mObtaNpLr",
  "aws_user_pools_web_client_id": "7iog9377sanq8q6154gbura1to",
  "oauth": {}
  // "aws_project_region": "us-east-1",
  // "aws_cognito_identity_pool_id": "us-east-1_PrQw1QP5E",
  // "aws_cognito_region": "us-east-1",
  // "aws_user_pools_id": "us-east-1_PrQw1QP5E",
  // "aws_user_pools_web_client_id": "5u5pfblvlnmvohvf2bqm0v2ri6",
  // "oauth": {}
};