import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useCubeQuery } from '@cubejs-client/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core';

import palette from '../../theme/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: 0,
  },
  head: {
    backgroundColor: palette.background.gray,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  status: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  tableRow: {
    padding: '0 5px',
    '.MuiTableRow-root.MuiTableRow-hover&:hover': {
      backgroundColor: palette.primary.action,
    },
  },
  hoverable: {
    '&:hover': {
      color: `${palette.primary.normal}`,
      cursor: `pointer`,
    },
  },
  arrow: {
    fontSize: 10,
    position: 'absolute',
  },
}));

const TableComponent = (props) => {
  const history = useHistory();

  function handleClick(str) {
    history.push(str);
  }

  const {
    className,
    sorting = [],
    setSorting,
    query,
    countQuery,
    rowsPerPage,
    page,
    setRowsPerPage,
    setPage,
    ...rest
  } = props;

  const classes = useStyles();

  const tableHeaders = [
    {
      text: 'ID',
      value: 'User.id',
    },
    {
      text: 'Email',
      value: 'User.email',
    },
    {
      text: 'CPF',
      value: 'User.nationalId',
    },
    {
      text: 'RG',
      value: 'User.nationalIdPlus',
    },
    {
      text: 'Telefone',
      value: 'Profile.phoneNumber',
    },
    {
      text: 'Status',
      value: 'User.status',
    },
    {
      text: 'Step',
      value: 'User.step',
    },
    {
      text: 'Skill',
      value: 'User.skill',
    },
    {
      text: 'Data de criação',
      value: 'User.createdAt',
    },
  ];
  const load = useCubeQuery(query);

  const count = useCubeQuery(countQuery);
  
  if (load.isLoading || count.isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: `${124 + rowsPerPage * 54}px`,
          background: '#fff',
          borderRadius: '5px',
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (load.error || count.error) {
    return <pre>{load.error.toString()}</pre>;
  }
  if (!load.resultSet || !count.resultSet) {
    return null;
  }
  if (load.resultSet && count.resultSet) {
    let announcements = load.resultSet.tablePivot();

    const handlePageChange = (event, page) => {
      setPage(page);
    };
    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(event.target.value);
    };
    const handleSetSorting = (str) => {
      setSorting([str, sorting[1] === 'desc' ? 'asc' : 'desc']);
    };

    return (
      <Card {...rest} padding={'0'} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead className={classes.head}>
                  <TableRow>
                    {tableHeaders.map((item) => (
                      <TableCell
                        key={item.value + Math.random()}
                        className={classes.hoverable}
                        onClick={() => {
                          handleSetSorting(`${item.value}`);
                        }}
                      >
                        <span>{item.text}</span>
                        <Typography className={classes.arrow} variant="body2" component="span">
                          {sorting[0] === item.value ? (
                            sorting[1] === 'desc' ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )
                          ) : null}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {announcements.map((obj) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={obj['User.id']}
                    >
                      <TableCell
                        className={classes.hoverable}
                        onClick={() => handleClick(`/user/${obj['User.id']}`)}
                        >
                        {obj['User.id']}
                        <Typography className={classes.arrow} variant="body2" component="span">
                          <OpenInNewIcon fontSize="small" />
                        </Typography>
                      </TableCell>
                      <TableCell>{obj['User.email']}</TableCell>
                      <TableCell>{obj['User.nationalId']}</TableCell>
                      <TableCell>{obj['User.nationalIdPlus']}</TableCell>
                      <TableCell>{obj['Profile.phoneNumber']}</TableCell>
                      <TableCell>{['ACTIVE'].includes(obj['User.status']) ? 'Ativo' : 'Inativo'}</TableCell>
                      <TableCell>{obj['User.step']}</TableCell>
                      <TableCell>{obj['User.skill']}</TableCell>
                      <TableCell>{moment(obj['User.createdAt']).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={count?.resultSet?.tablePivot()?.length ? parseInt(count?.resultSet?.tablePivot()[0]['User.count']) : 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      </Card>
    );
  }
};

TableComponent.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired,
};

export default TableComponent;
