import { session } from '../../helpers/session';

const paths = {
  local: 'document/admin/update',
  production: 'document/admin/update',
  staging: 'stage/admin/update',
} as any;

const {
  REACT_APP_SLS_STAGE,
  REACT_APP_STOKADO_API,
} = process.env;

const getPath = () => paths[REACT_APP_SLS_STAGE || 'local'];

export const updateDocument = async (data: any) => {
  const accessToken = session('accessToken') as string;

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: accessToken },
    body: JSON.stringify(data)
  };

  return fetch(`https://api.stokado.com.br/${getPath()}`, requestOptions);
};