import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { useCubeQuery } from '@cubejs-client/react';
import { useParams } from 'react-router-dom';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Chip from '@material-ui/core/Chip';
import { updateDocument } from '../services/api/document-api';

const useStyles = makeStyles({
  media: {
    height: 140,
  },
});

const documentTypes = {
  NATIONAL_ID: 'CPF',
  ADDRESS: 'Comprovante de endereço',
  SELF: 'Self'
}

export default function MediaCard() {
  const classes = useStyles();

  const [startDate, setStartDate] = React.useState(new Date('2022-01-01T00:00:00'));
  const [finishDate, setFinishDate] = React.useState(new Date('2030-01-01T00:00:00'));
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const [textNationalId, setTextNationalId] = useState('');
  const [textAddress, setTextAddress] = useState('');
  const [textSelf, setTextSelf] = useState('');

  let { id } = useParams();

  const documentsSkeleton = [
    {
      id: 0,
      name_file: null,
      status: 'PENDING_USER',
      type: 'NATIONAL_ID',
      created_at: null,
      updated_at: null,
      description: null,
      user_id: 0
    },
    {
      id: 0,
      name_file: null,
      status: 'PENDING_USER',
      type: 'ADDRESS',
      created_at: null,
      updated_at: null,
      description: null,
      user_id: 0,
    },
    {
      id: 0,
      name_file: null,
      status: 'PENDING_USER',
      type: 'SELF',
      created_at: null,
      updated_at: null,
      description: null,
      user_id: 0,
    },
  ]

  const query = {
    measures: [
      'Document.count',
    ],
    timeDimensions: [
      {
        dimension: 'Document.createdAt',
      },
      {
        dimension: 'Document.createdAt',
        dateRange: [startDate, finishDate],
        granularity: null,
      }
    ],
    dimensions: [
      'Document.id',
      'User.id',
      'Document.type',
      'Document.nameFile',
      'Document.status',
      'Document.description',
    ],
    filters: [
      {
        dimension: 'User.id',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  };

  let lengthFiles = 0;

  const { resultSet, error, isLoading } = useCubeQuery(query);

  documentsSkeleton.map((d) => {
    const search = resultSet?.loadResponse?.data?.filter((df) => df['Document.type'] === d.type);
    if (search && search.length) {
      if (search[0]['Document.nameFile']) {
        lengthFiles++;
      }
      d.id = search[0]['Document.id'];
      d.type = search[0]['Document.type'];
      d.status = search[0]['Document.status'];
      d.name_file = search[0]['Document.nameFile'];
      d.created_at = search[0]['Document.createdAt'];
      d.updated_at = search[0].updated_at;
      d.description = search[0]['Document.description'];
    }
    return d;
  });

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (!resultSet) {
    return null;
  }

  const setText = (value, type) => {
    if (type === 'NATIONAL_ID') setTextNationalId(value);
    if (type === 'ADDRESS') setTextAddress(value);
    if (type === 'SELF') setTextSelf(value);
  }

  const approvedDocument = (id, status, type) => {
    let description;

    if (type === 'NATIONAL_ID') {
      description = textNationalId;
    };
    if (type === 'ADDRESS') {
      description = textAddress;
    };
    if (type === 'SELF') {
      description = textSelf;
    };
    
    setIsLoadingApi(true);

    updateDocument({
      id,
      status,
      description
    })
    .finally(() => {
      setTimeout(() => {
        setIsLoadingApi(false);
        setFinishDate(new Date().toISOString());
      }, 300)
    })
  }

  const statusStyle = {
    REPROVED: { 
       color: 'red',
       label: 'REPROVADO'
    },
    APPROVED: { 
      color: 'green',
      label: 'APROVADO'
    },
    PENDING_USER: { 
      color: 'blue',
      label: 'USUÁRIO'
    },
    PENDING_SYSTEM: { 
      color: 'orange',
      label: 'SISTEMA'
    }
  }

  return (
    <div>
        <Alert severity="warning">Para aprovar ou reprovar os documentos é necessarios que todos estejam anexados!</Alert><br/>
        <Grid item lg={12} sm={12} xl={12} xs={12} container spacing={2}>
        { documentsSkeleton?.map((value, key) => (
          <Grid item lg={4} sm={4} xl={4} xs={12} key={key}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={`https://stokado-prod-profile-sec-docs-bucket-20211125014201077500000009.s3.us-west-2.amazonaws.com/${value?.name_file}`}
                  onClick={e => window.open(`https://stokado-prod-profile-sec-docs-bucket-20211125014201077500000009.s3.us-west-2.amazonaws.com/${value?.name_file}`,'_blank')}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    { documentTypes[value?.type] }
                  </Typography>
                  <Chip label={statusStyle[value?.status].label} style={{ background: statusStyle[value?.status].color, color: 'white' }}/>
                </CardContent>
              </CardActionArea>
              <Typography gutterBottom variant="h6" component="h6">
                { value?.description }
              </Typography>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Observação"
                style={{ width: '98%' }}
                onChange={ e => setText(e?.target?.value, value?.type)}
              />
              <CardActions>
                { isLoadingApi ? (<CircularProgress color="secondary" />) : null }
                {
                  (['PENDING_SYSTEM', 'REPROVED'].includes(value?.status) && lengthFiles) ? (
                  <Button size="small" color="primary" onClick={e => approvedDocument(value?.id, 'APPROVED', value?.type)}>
                    Aprovar
                  </Button> ) : ''
                }
                {
                  (!['PENDING_USER', 'APPROVED'].includes(value?.status) && lengthFiles) ? (
                  <Button size="small" color="primary" onClick={e => approvedDocument(value?.id, 'REPROVED', value?.type)}>
                    Reprovar
                  </Button> ) : ''
                }
              </CardActions>
            </Card>
          </Grid>
        )) }
      </Grid>
    </div>
  );
}