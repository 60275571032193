import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Avatar, Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    height: 'auto',
  },
  name: {
    fontSize: 18,
    fontWeight: '500',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px 13px 5px',
  },
  avatar: {
    height: 60,
    width: 60,
    flexShrink: 0,
    flexGrow: 0,
    marginRight: 25,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  textRow: {
    marginTop: theme.spacing(2),
  },
  dateText: {
    width: 60,
    marginRight: 30,
    display: 'inline-block',
    fontSize: 13,
  },
  smallBoldText: {
    fontSize: 14,
    fontWeight: '500',
  },
}));

const AccountProfile = (props) => {
  const {
    className,
    id,
    userNickName,
    nationalId,
    nationalIdPlus,
    email,
    step,
    status,
    skill,
    phoneNumber,
    address,
    twoFactor,
    bankAccount,
    ...rest
  } = props;

  const classes = useStyles();

  const addressParsed = address ? JSON.parse(address) : {};

  const bankAccountParsed = bankAccount ? JSON.parse(bankAccount) : {};

  const user = {
    avatar: '/images/avatars/avatar_1.png',
  };

  return (
    <Card {...rest} height={'auto'} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <Avatar className={classes.avatar} src={user.avatar} />
          <div>
            <Typography variant="h4" className={classes.name}>
              {userNickName}
            </Typography>
            <br/>
            <Typography color="textSecondary">
              {email || '-'}
            </Typography>
            <br/>
            <Typography color="textSecondary">
              Status: {status || '-'}
            </Typography>
            <Typography color="textSecondary">
              Step: {step || '-'}
            </Typography>
            <Typography color="textSecondary">
              Skills: {skill || '-'} 
            </Typography>
            <br/>
            <Typography color="textSecondary">
              SEGURANÇA:
            </Typography>
            <Typography color="textSecondary">
                { ` - SMS: ${['TRADE'].includes(skill) ? 'HABILITAO' : 'DESABILITADO'}` }
            </Typography>
            <Typography color="textSecondary">
                { ` - DOCUMENTOS: ${['TRADE_MASTER'].includes(skill) ? 'HABILITAO' : 'DESABILITADO'}` }
            </Typography>
            <Typography color="textSecondary">
                { ` - 2FA: ${twoFactor ? 'HABILITAO' : 'DESABILITADO'}` }
            </Typography>
          </div>
        </div>
        <div className={classes.progress}>
          <Divider className={classes.divider} />
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              ID
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              {id}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              CPF
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              {nationalId || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              RG
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              {nationalIdPlus || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              TELEFONE
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              {phoneNumber || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              ENREREÇO
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { addressParsed?.address || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              NUMERO
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { addressParsed?.number || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              CIDADE
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { addressParsed?.city || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              BAIRRO
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { addressParsed?.neighborhood || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              CEP
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { addressParsed?.zip_code || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              COMP.
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { addressParsed?.complement || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              Banco
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { bankAccountParsed?.name || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              AG:
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { bankAccountParsed?.agency || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              CT:
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { bankAccountParsed?.account || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              DIGITO:
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { bankAccountParsed?.digit || '-' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              PIX:
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            { bankAccountParsed?.pix?.target || '-' } | { bankAccountParsed?.pix?.value || '-' }
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
};

export default AccountProfile;
