import "leaflet/dist/leaflet.css";

import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from "leaflet";
import { useCubeQuery } from '@cubejs-client/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';

var myIconGreen = Leaflet.icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
  // iconSize: [38, 95],
});

var myIconRed = Leaflet.icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
  // iconSize: [38, 95],
});

const Map = (props) => {

  const { query } = props;

  const { resultSet, isLoading, error } = useCubeQuery(query);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          borderRadius: '5px',
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (error) {
    return <pre>{error.toString()}</pre>;
  }

  let announcements = resultSet?.tablePivot();

  let announcementsMap = [];

  if (announcements?.length) {
    announcements.map((item) => {
      if (item['Announcement.address']) {
        item['Announcement.address'] = JSON.parse(item['Announcement.address']);

        if (item['Announcement.address']?.location?.latitude && item['Announcement.address']?.location?.longitude) {
          announcementsMap.push(item);
        }
      }
    })
  }

  if (!announcementsMap?.length) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          borderRadius: '5px',
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <MapContainer center={[announcements[0]['Announcement.address']?.location?.latitude, announcements[0]['Announcement.address']?.location?.longitude]} zoom={11} scrollWheelZoom={false} style={{ width: '100%', height: '500px' }}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
        id='mapbox/streets-v11'
      />
      {announcementsMap?.map((item, key) => (
        <Marker
          key={key}
          icon={item['Announcement.publish'] ? myIconGreen : myIconRed}
          position={[item['Announcement.address']?.location?.latitude, item['Announcement.address']?.location?.longitude]}
        >
          <Popup>
            ID: {item['Announcement.id']}
            <br />
            Titulo: {item['Announcement.title']}
            <br />
            Descrição: {item['Announcement.description']}
            <br />
            Valor: {item['Announcement.value']}
            <br />
            <Link href={`#/announcement/${item['Announcement.id']}`}>
              Visualizar
            </Link>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;
