import { Route, Redirect } from 'react-router-dom';

import React from 'react'

const PrivateRoute = (props) => {
  const session = window.localStorage.getItem('keyAccess');
  return session
    ? <Route {...props} />
    : <Route {...props} component={() => <Redirect to="/login" />} />
}

export default PrivateRoute;