/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import palette from '../../../../theme/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: palette.primary.contrastText,
  },
  flexGrow: {
    flexGrow: 1,
  },
  link: {
    'font-family': 'Roboto',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    textDecoration: 'none',
    color: palette.text.link,
    borderRadius: '5px',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      textDecoration: 'none',
      color: palette.text.link,
      backgroundColor: 'rgba(213,213,226,.1)',
    },
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  git: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '26px',
    marginLeft: 7,
  },
}));

const Topbar = (props) => {
  const [signOut, setSignOut] = React.useState(false);

  const SignOut = () => {
    window.localStorage.removeItem('keyAccess');
    window.localStorage.clear();
    setTimeout(() => {
      setSignOut(true);
    }, 300);
  }

  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  return signOut ? (
    <Redirect to="/" />
  ) : (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src="https://www.stokado.com.br/images/logo.png" width='120' />
        </RouterLink>
        <div className={classes.flexGrow} />
        <a
          className={classes.link}
          onClick={SignOut}>
          <AmplifySignOut
            buttonText="Sair"
          />
        </a>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
