import React from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
  },
  chatLeft: {
    backgroundColor: '#eeeeeee6',
    padding: '10px',
    borderRadius: '15px',
  },
  chatRight: {
    backgroundColor: '#dbf7c6',
    padding: '10px',
    borderRadius: '15px',
  },
  chatPositionRight: {
    textAlign: 'right',
    display: 'block',
    paddingLeft: '50%',
  }
}));

export default function ChatList(props) {
  const classes = useStyles();

  const { chat, owner, announcementId } = props;

  const chatParsed = JSON.parse(chat);

  const query = {
    dimensions: [
      'User.email',
    ],
    filters: [
      {
        dimension: 'Announcement.id',
        operator: 'equals',
        values: [`${announcementId}`],
      },
    ],
  };

  const { resultSet, error, isLoading } = useCubeQuery(query);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (!resultSet) {
    return null;
  }

  if (resultSet) {
    let data = resultSet.tablePivot();
    
    let announcementData = data[0] || [];

    return (
      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders">
          { chatParsed.map((item) => (item.user_id == owner ?
            (<ListItem className={classes.chatPositionRight}>
              <div className={classes.chatRight}>
                <ListItemText primary={`${item?.create_at} - ${item?.message}`} />
            </div>
            </ListItem>) :
            (<ListItem>
              <div className={classes.chatLeft}>
                <ListItemText primary={`${item?.create_at} - ${item?.message}`} />
                <span>{announcementData['User.email']}</span>
              </div>
            </ListItem>)
          )) }
        </List>
      </div>
    );
  }
}