import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Main from '../layouts/Main/Main';
import Toolbar from '../components/Toolbar.js';
import ReservedTable from '../components/tables/Reserved';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: 15,
  },
}));

const DataTablePage = () => {
  const classes = useStyles();
  const tabs = ['Todos', 'Publicado', 'Não publicado'];
  const [statusFilter, setStatusFilter] = React.useState(0);
  const [startDate, setStartDate] = React.useState(new Date('2019-01-01T00:00:00'));
  const [finishDate, setFinishDate] = React.useState(new Date('2030-01-01T00:00:00'));
  const [priceFilter, setPriceFilter] = React.useState(null);
  const [sorting, setSorting] = React.useState(['AnnouncementReserved.createdAt', 'desc']);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  
  const [targetFilter, setTargetFilter] = useState('status');
  const [targetFilterModel, setTargetFilterModel] = useState('AnnouncementReserved');
  const [targetFilterValue, setTargetFilterValue] = useState(0);

  const targetFilterInputValues = [
    {
      value: 'status',
      label: 'Status',
      model: 'AnnouncementReserved',
    },
    {
      value: 'daysRented',
      label: 'Dias',
      model: 'AnnouncementReserved',
    },
    {
      value: 'id',
      label: 'ID do Anúncio',
      model: 'Announcement',
    },
    {
      value: 'email',
      label: 'Email',
      model: 'User',
    },
  ];

  const query = {
    limit: rowsPerPage,
    offset: rowsPerPage * page,
    order: {
      [`${sorting[0]}`]: sorting[1],
    },
    timeDimensions: [
      {
        dimension: 'AnnouncementReserved.createdAt',
        dateRange: [startDate, finishDate],
        granularity: null,
      },
    ],
    measures: [
      'AnnouncementReserved.count',
    ],
    dimensions: [
      'AnnouncementReserved.id',
      'AnnouncementReserved.status',
      'AnnouncementReserved.descriptionGuest',
      'AnnouncementReserved.descriptionGuardian',
      'AnnouncementReserved.daysRented',
      'Announcement.id',
      'User.id',
      'User.email',
      'AnnouncementReserved.createdAt'
    ],
    filters: targetFilterValue ? [{
      dimension: `${targetFilterModel}.${targetFilter}`,
      operator: 'equals',
      values: [`${targetFilterValue}`],
    }] : [],
  };

  const countQuery = {
    order: {
      [`${sorting[0]}`]: sorting[1],
    },
    timeDimensions: [
      {
        dimension: 'AnnouncementReserved.createdAt',
        dateRange: [startDate, finishDate],
        granularity: null,
      },
    ],
    measures: [
      'AnnouncementReserved.count',
    ],
  };

  return (
    <Main>
      <div className={classes.root}>
        <Toolbar
          startDate={startDate}
          setStartDate={setStartDate}
          finishDate={finishDate}
          setFinishDate={setFinishDate}
          priceFilter={priceFilter}
          setPriceFilter={setPriceFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          setTargetFilter={setTargetFilter}
          setTargetFilterValue={setTargetFilterValue}
          targetFilterInputValues={targetFilterInputValues}
          setTargetFilterModel={setTargetFilterModel}
          tabs={tabs}
        />
        <div className={classes.content}>
          <ReservedTable
            sorting={sorting}
            setSorting={setSorting}
            query={query}
            countQuery={countQuery}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </Main>
  );
};

export default DataTablePage;
