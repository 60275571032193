import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useCubeQuery } from '@cubejs-client/react';
import { Grid } from '@material-ui/core';
import Main from '../layouts/Main/Main';
import AccountProfile from '../components/detail/AccountProfile';
import BarChart from '../components/BarChart';
import AnnouncementsTable from '../components/tables/Announcements';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserSearch from '../components/UserSearch';
import KPIChart from '../components/KPIChart';
import DocumentsProfile from '../components/DocumentsProfile';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  row: {
    display: 'flex',
    margin: '0 -15px',
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  info: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sales: {
    marginTop: theme.spacing(3),
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const UsersPage = (props) => {
  const [sorting, setSorting] = React.useState(['AnnouncementReserved.createdAt', 'desc']);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const classes = useStyles();
  let { id } = useParams();
  const query = {
    measures: [
      'User.count',
    ],
    timeDimensions: [{
      dimension: 'User.createdAt',
    }],
    dimensions: [
      'User.id',
      'User.email',
      'User.nationalId',
      'User.nationalIdPlus',
      'User.status',
      'User.step',
      'User.skill',
      'Profile.nickname',
      'Profile.address',
      'Profile.phoneNumber',
      'Profile.bankAccount',
      'TwoFactor.id',
      'TwoFactor.status',
      'TwoFactor.createdAt',
    ],
    filters: [
      {
        dimension: 'User.id',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  };
  const barChartQuery = {
    measures: ['Announcement.count'],
    timeDimensions: [
      {
        dimension: 'Announcement.createdAt',
        granularity: 'month',
        dateRange: 'This week',
      },
    ],
    dimensions: ['Announcement.publish'],
    filters: [
      {
        dimension: 'User.id',
        operator: 'equals',
        values: [id],
      },
    ],
  };
  const cards = [
    {
      title: 'Anúncios',
      query: {
        measures: ['Announcement.count'],
        filters: [
          {
            dimension: 'User.id',
            operator: 'equals',
            values: [`${id}`],
          },
        ],
      },
      duration: 1.25,
    },
    {
      title: 'Soma total dos anúncios',
      query: {
        measures: ['Announcement.value'],
        filters: [
          {
            dimension: 'User.id',
            operator: 'equals',
            values: [`${id}`],
          },
        ],
      },
      duration: 1.5,
    },
  ];
  const queryAnnouncement = {
    measures: [
      'Announcement.value',
      'Announcement.discountValue'
    ],
    dimensions: [
      'Announcement.id',
      'User.id',
      'User.email',
      'Announcement.createdAt',
      'Announcement.title',
      'Announcement.description',
      'Announcement.address',
      'Announcement.reserved',
      'Announcement.publish',
    ],
    filters: [
      {
        dimension: 'User.id',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  };
  const queryAnnouncementCount = {
    measures: [
      'Announcement.count',
      'Announcement.value',
    ],
    filters: [
      {
        dimension: 'User.id',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  };
  
  const { resultSet, error, isLoading } = useCubeQuery(query);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (!resultSet) {
    return null;
  }
  if (resultSet) {
    let data = resultSet.tablePivot();
    let userData = data[0] || [];
    return (
      <Main>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item lg={8} sm={12} xl={12} xs={12}>
              <UserSearch />
            </Grid>
            <Grid item lg={5} sm={12} xl={12} xs={12}>
              <AccountProfile
                userNickName={userData['Profile.nickname']}
                email={userData['User.email']}
                step={userData['User.step']}
                status={userData['User.status']}
                skill={userData['User.skill']}
                nationalId={userData['User.nationalId']}
                nationalIdPlus={userData['User.nationalIdPlus']}
                phoneNumber={userData['Profile.phoneNumber']}
                bankAccount={userData['Profile.bankAccount']}
                address={userData['Profile.address']}
                twoFactor={userData['TwoFactor.status']}
                id={id}
              />
            </Grid>
            <Grid item lg={7} sm={12} xl={12} xs={12}>
              <div className={classes.row}>
                {cards?.map((item, index) => {
                  return (
                    <Grid className={classes.info} key={item.title + index} item lg={6} sm={6} xl={6} xs={12}>
                      <KPIChart {...item} />
                    </Grid>
                  );
                })}
              </div>
              <div className={classes.sales}>
                <BarChart query={barChartQuery} dates={['This year', 'Last year']} />
              </div>
            </Grid>
            <Grid className={classes.info} item lg={12} sm={12} xl={12} xs={12} spacing={2}>
              <DocumentsProfile />
            </Grid>
            <Grid className={classes.info} item lg={12} sm={12} xl={12} xs={12} spacing={2}>
              <AnnouncementsTable
                query={queryAnnouncement}
                countQuery={queryAnnouncementCount}
                sorting={sorting}
                setSorting={setSorting}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                setPage={setPage}
              />
            </Grid>
          </Grid>
        </div>
      </Main>
    );
  }
};

export default UsersPage;
