import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    height: 'auto',
  },
  name: {
    fontSize: 18,
    fontWeight: '500',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px 13px 5px',
  },
  avatar: {
    height: 60,
    width: 60,
    flexShrink: 0,
    flexGrow: 0,
    marginRight: 25,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  textRow: {
    marginTop: theme.spacing(2),
  },
  dateText: {
    width: 60,
    marginRight: 30,
    display: 'inline-block',
    fontSize: 13,
  },
  smallBoldText: {
    fontSize: 14,
    fontWeight: '500',
  },
}));

const AccountProfile = (props) => {
  const {
    className,
    data,
    ...rest
  } = props;

  const classes = useStyles();

  const addressParsed = data?.['Announcement.address'] ? JSON.parse(data?.['Announcement.address']) : {};

  const resourceParsed = data?.['Announcement.spaceResources'] ? JSON.parse(data['Announcement.spaceResources']) : {};

  return (
    <Card {...rest} height={'auto'} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography variant="h4" className={classes.name}>
              {data['Announcement.title']}
            </Typography>
            <br/>
            <Typography color="textSecondary">
              {data['Announcement.description'] || '-'}
            </Typography>
            <br/>
          </div>
        </div>
        <div className={classes.progress}>
          <Divider className={classes.divider} />
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              ID
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              {data['Announcement.id'] || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              STATUS
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              {data['Announcement.publish'] === 1 ? 'ATIVO': 'INATIVO' || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              RESERVADO
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              {data['Announcement.reserved'] ? 'RESERVADO' : 'SEM RESERVA' }
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              PUBLICADO
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              {data['Announcement.createdAt'] || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              VAlOR
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            {`R$ ${data['Announcement.value']?.toFixed(2)}` || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              ENDEREÇO
            </Typography>
            <br/>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              - {`CIDADE: ${addressParsed?.city}` || '-'} <br/>
              - {`ESTADO: ${addressParsed?.state}` || '-'} <br/>
              - {`NÚMERO: ${addressParsed?.number}` || '-'} <br/>
              - {`ENDEREÇO: ${addressParsed?.address}` || '-'} <br/>
              - {`CEP: ${addressParsed?.zip_code}` || '-'} <br/>
              - {`COMPLEMENTO: ${addressParsed?.complement}` || '-'} <br/>
              - {`BAIRRO: ${addressParsed?.neighborhood}` || '-'} <br/>
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              DESCONTO
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            {data['Announcement.discount'] || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              TIPO
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            {data['Announcement.spaceType'] || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              TAMANHO
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            {`${data['Announcement.spaceSize']?.toFixed(2)}` || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              RECURSOS
            </Typography>
            <br/>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              - PADLOCK: {`${resourceParsed?.PADLOCK ? 'SIM' : 'NÃO'}` || '-'}<br/>
              - NO_STAIRS: {`${resourceParsed?.NO_STAIRS ? 'SIM' : 'NÃO'}` || '-'}<br/>
              - NO_ANIMALS: {`${resourceParsed?.NO_ANIMALS ? 'SIM' : 'NÃO'}` || '-'}<br/>
              - COVERED_SPACE: {`${resourceParsed?.COVERED_SPACE ? 'SIM' : 'NÃO'}` || '-'}<br/>
              - SMOKE_DETECTOR: {`${resourceParsed?.SMOKE_DETECTOR ? 'SIM' : 'NÃO'}` || '-'}<br/>
              - AIR_CONDITIONING: {`${resourceParsed?.AIR_CONDITIONING ? 'SIM' : 'NÃO'}` || '-'}<br/>
              - PRIVATE_SECURITY: {`${resourceParsed?.PRIVATE_SECURITY ? 'SIM' : 'NÃO'}` || '-'}<br/>
              - SMOKING_PROHIBITED: {`${resourceParsed?.SMOKING_PROHIBITED ? 'SIM' : 'NÃO'}` || '-'}<br/>
              - SURVEILANCE_CAMERA: {`${resourceParsed?.SURVEILANCE_CAMERA ? 'SIM' : 'NÃO'}` || '-'}<br/>
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              ACESSO
            </Typography>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
            {`${data['Announcement.accessType']}` || '-'}
            </Typography>
          </div>
          <div className={classes.textRow}>
            <Typography className={classes.dateText} color="textSecondary" variant="body1" component="span">
              DIAS
            </Typography>
            <br/>
            <Typography className={classes.smallBoldText} variant="body1" component="span">
              - {`friday: ${JSON.parse(data['Announcement.accessDay']).friday?.active}` || '-'} - 
              ==== {`start: ${JSON.parse(data['Announcement.accessDay'])?.friday?.start || ''}` || '-'} |
              ==== {`end: ${JSON.parse(data['Announcement.accessDay'])?.friday?.end  || ''}` || '-'}
              <br/>
              - {`monday: ${JSON.parse(data['Announcement.accessDay']).monday?.active}` || '-'} - 
              ==== {`start: ${JSON.parse(data['Announcement.accessDay'])?.monday?.start || ''}` || '-'} |
              ==== {`end: ${JSON.parse(data['Announcement.accessDay'])?.monday?.end  || ''}` || '-'}
              <br/>
              - {`tuesday: ${JSON.parse(data['Announcement.accessDay']).tuesday?.active}` || '-'} - 
              ==== {`start: ${JSON.parse(data['Announcement.accessDay'])?.tuesday?.start || ''}` || '-'} |
              ==== {`end: ${JSON.parse(data['Announcement.accessDay'])?.tuesday?.end  || ''}` || '-'}
              <br/>
              - {`saturday: ${JSON.parse(data['Announcement.accessDay']).saturday?.active}` || '-'} - 
              ==== {`start: ${JSON.parse(data['Announcement.accessDay'])?.saturday?.start || ''}` || '-'} |
              ==== {`end: ${JSON.parse(data['Announcement.accessDay'])?.saturday?.end  || ''}` || '-'}
              <br/>
              - {`thursday: ${JSON.parse(data['Announcement.accessDay']).thursday?.active}` || '-'} - 
              ==== {`start: ${JSON.parse(data['Announcement.accessDay'])?.thursday?.start || ''}` || '-'} |
              ==== {`end: ${JSON.parse(data['Announcement.accessDay'])?.thursday?.end  || ''}` || '-'}
              <br/>
              - {`wednesday: ${JSON.parse(data['Announcement.accessDay']).wednesday?.active}` || '-'} - 
              ==== {`start: ${JSON.parse(data['Announcement.accessDay'])?.wednesday?.start || ''}` || '-'} |
              ==== {`end: ${JSON.parse(data['Announcement.accessDay'])?.wednesday?.end  || ''}` || '-'}
              <br/>
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
};

export default AccountProfile;
