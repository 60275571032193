import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { useCubeQuery } from '@cubejs-client/react';
import { useParams } from 'react-router-dom';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles({
  media: {
    height: 140,
  },
});

export default function MediaCard() {
  const classes = useStyles();

  let { id } = useParams();

  const query = {
    measures: [
      'AnnouncementImages.count',
    ],
    timeDimensions: [{
      dimension: 'AnnouncementImages.createdAt',
    }],
    dimensions: [
      'AnnouncementImages.id',
      'Announcement.id',
      'AnnouncementImages.nameFile',
      'AnnouncementImages.status',
      'AnnouncementImages.description',
    ],
    filters: [
      {
        dimension: 'Announcement.id',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  };

  const { resultSet, error, isLoading } = useCubeQuery(query);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (!resultSet) {
    return null;
  }


  if (resultSet) {
    let announcementImages = resultSet?.tablePivot() || [];

    return (
      <Grid item lg={12} sm={12} xl={12} xs={12} container spacing={2}>
        { announcementImages?.map((item, key) => (
          <Grid item lg={4} sm={4} xl={4} xs={12} key={key}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={`https://stokado-prod-anncmt-images-bucket-20211125014153574900000007.s3.us-west-2.amazonaws.com/${item['AnnouncementImages.nameFile']}`}
                  title="Contemplative Reptile"
                  onClick={e => window.open(`https://stokado-prod-anncmt-images-bucket-20211125014153574900000007.s3.us-west-2.amazonaws.com/${item['AnnouncementImages.nameFile']}`,'_blank')}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    { item['AnnouncementImages.description'] }
                  </Typography>
                  <Chip label={item['AnnouncementImages.status'] === 'ENABLED' ? 'ATIVO' : 'INATIVA'} />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )) }
      </Grid>
    );
  }
  
}