import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Main from '../layouts/Main/Main';
import KPIChart from '../components/KPIChart';
import Map from '../components/Map';
// import BarChart from '../components/BarChart.js';
// import DoughnutChart from '../components/DoughnutChart.js';



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const query = {
  limit: 100,
  offset: 0,
  order: {
		"Announcement.createdAt": "desc"
  },
  measures: ['Announcement.value',],
  dimensions: [
    'Announcement.id',
    'Announcement.title',
    'Announcement.description',
    'Announcement.address',
    'Announcement.publish',
  ]
};

const cards = [
  {
    title: 'Total de usuários',
    query: { measures: ['User.count'] },
    difference: 'User',
    duration: 1.5,
  },
  {
    title: 'Total de Anúncios',
    query: { measures: ['Announcement.count'] },
    difference: 'Announcement',
    duration: 1.5,
  },
  {
    title: 'Total de Anúncios Ativos',
    query: {
      measures: ['Announcement.count'],
      filters: [
        {
          dimension: `Announcement.publish`,
          operator: 'equals',
          values: ['1'],
        }
      ]
    },
    difference: 'Announcement',
    duration: 1.5,
  },
  {
    title: 'Total de Anúncios Inativos',
    query: {
      measures: ['Announcement.count'],
      filters: [
        {
          dimension: `Announcement.publish`,
          operator: 'equals',
          values: ['0'],
        }
      ]
    },
    difference: 'Announcement',
    duration: 1.5,
  },
  {
    title: 'Total de Reservas',
    query: { measures: ['AnnouncementReserved.count'] },
    difference: 'AnnouncementReserved',
    duration: 1.5,
  },
  {
    title: 'Total de Reservas ativas',
    query: {
      measures: ['AnnouncementReserved.count'],
      filters: [
        {
          dimension: `AnnouncementReserved.status`,
          operator: 'equals',
          values: [`ACTIVE`],
        }
      ]
    },
    difference: 'AnnouncementReserved',
    duration: 1.5,
  },
  {
    title: 'Total de Reservas rejeitada pelo guardião',
    query: {
      measures: ['AnnouncementReserved.count'],
      filters: [
        {
          dimension: `AnnouncementReserved.status`,
          operator: 'equals',
          values: [`REPROVED_GUARDIAN`]
        }
      ]
    },
    difference: 'AnnouncementReserved',
    duration: 1.5,
  },
  {
    title: 'Total de Reservas rejeitada pelo anfitrião',
    query: {
      measures: ['AnnouncementReserved.count'],
      filters: [
        {
          dimension: `AnnouncementReserved.status`,
          operator: 'equals',
          values: [`REPROVED_GUEST`]
        }
      ]
    },
    difference: 'AnnouncementReserved',
    duration: 1.5,
  },
  {
    title: 'Total de pedidos de assinaturas para cancelamento',
    query: {
      measures: ['AnnouncementReserved.count'],
      filters: [
        {
          dimension: `AnnouncementReserved.status`,
          operator: 'equals',
          values: [`DECLINED_GUEST`, `DECLINED_GUARDIAN`]
        }
      ]
    },
    difference: 'AnnouncementReserved',
    duration: 1.5,
  },
  {
    title: 'Total de reservas encerradas',
    query: {
      measures: ['AnnouncementReserved.count'],
      filters: [
        {
          dimension: `AnnouncementReserved.status`,
          operator: 'equals',
          values: [`INACTIVE`]
        }
      ]
    },
    difference: 'AnnouncementReserved',
    duration: 1.5,
  },
];
// const barChartQuery = {
//   measures: ['Orders.count'],
//   timeDimensions: [
//     {
//       dimension: 'Orders.createdAt',
//       granularity: 'day',
//       dateRange: 'This week',
//     },
//   ],
//   dimensions: ['Orders.status'],
//   filters: [
//     {
//       dimension: 'Orders.status',
//       operator: 'notEquals',
//       values: ['completed'],
//     },
//   ],
// };
// const doughnutChartQuery = {
//   measures: ['Orders.count'],
//   timeDimensions: [
//     {
//       dimension: 'Orders.createdAt',
//     },
//   ],
//   filters: [],
//   dimensions: ['Orders.status'],
// };

// const mapPackageIcon = Leaflet.icon({
//   iconUrl: 'https://w7.pngwing.com/pngs/571/321/png-transparent-google-map-mark-computer-icons-location-symbol-map-location-miscellaneous-angle-heart-thumbnail.png',
// });

const Dashboard = () => {
  const classes = useStyles();
  return (
    <Main>
      <div className={classes.root}>
        <Grid container spacing={4}>
          {cards.map((item, index) => {
            return (
              <Grid key={item.title + index} item lg={3} sm={6} xl={3} xs={12}>
                <KPIChart {...item} />
              </Grid>
            );
          })}
          {/* <Grid item lg={8} md={12} xl={9} xs={12}>
            <BarChart query={barChartQuery} />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <DoughnutChart query={doughnutChartQuery} />
          </Grid> */}
        </Grid>
        <br/>
        <Grid item lg={12} md={12} xl={12} xs={12} spacing={4}>
          <Map query={query}></Map>
        </Grid>
      </div>
    </Main>
  );
};

export default Dashboard;
