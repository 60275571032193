import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: 25,
    fullWidth: true,
    display: 'flex',
    wrap: 'nowrap',
  },
  date: {},
  range: {},
}));

const Toolbar = (props) => {
  const {
    className,
    startDate,
    setStartDate,
    finishDate,
    setFinishDate,
    priceFilter,
    setPriceFilter,
    statusFilter,
    setStatusFilter,
    setTargetFilter,
    setTargetFilterValue,
    targetFilterInputValues,
    setTargetFilterModel,
    tabs,
    ...rest
  } = props;
  const [tabValue, setTabValue] = React.useState(statusFilter);
  const [rangeValue, rangeSetValue] = React.useState(priceFilter);
  const [targetFilterLabel, setTargetFilterLabel] = React.useState(targetFilterInputValues[0].value);

  const classes = useStyles();

  const handleChangeTab = (e, value) => {
    setTabValue(value);
    setStatusFilter(value);
  };
  const handleDateChange = (date) => {
    setStartDate(date);
  };
  const handleDateChangeFinish = (date) => {
    setFinishDate(date);
  };
  const handleChangeRange = (event, newValue) => {
    rangeSetValue(newValue);
  };
  const setRangeFilter = (event, newValue) => {
    setPriceFilter(newValue);
  };
  const handleChange = (event) => {
    setTargetFilter(event.target.value);
    setTargetFilterLabel(event.target.value);
    
    const [targetFiltered] = targetFilterInputValues.filter((f) => f.value === event.target.value);

    setTargetFilterModel(targetFiltered?.model);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={3}>
        <Grid item lg={3} sm={3} xl={3} xs={12} m={2}>
          <TextField
            style={{ width: '100%' }}
            id="outlined-select-currency"
            select
            label="Campo de busca"
            value={targetFilterLabel}
            onChange={handleChange}
            variant="outlined"
          >
            {targetFilterInputValues.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} sm={12} xl={3} xs={12} m={2}>
          <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              label={`Buscar`}
              variant="outlined"
              onChange={e => setTargetFilterValue(e?.target?.value)}
          />
        </Grid>
        <Grid className={classes.date} item lg={3} sm={6} xl={12} xs={12} m={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                id="date-picker-dialog"
                label={<span style={{ opacity: 0.6 }}>Data inicial</span>}
                format="MM/dd/yyyy"
                value={startDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid className={classes.date} item lg={3} sm={6} xl={12} xs={12} m={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                id="date-picker-dialog-finish"
                label={<span style={{ opacity: 0.6 }}>Data Final</span>}
                format="MM/dd/yyyy"
                value={finishDate}
                onChange={handleDateChangeFinish}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid className={classes.range} item lg={6} sm={6} xl={3} xs={12} m={2}>
          { priceFilter ? (<div>
            <Typography id="range-slider">Valor do anúncio</Typography>
            <Slider
              style={{ width: '100%' }}
              value={rangeValue}
              onChange={handleChangeRange}
              onChangeCommitted={setRangeFilter}
              aria-labelledby="range-slider"
              valueLabelDisplay="auto"
              min={0}
              max={2000}
            />
          </div>) : null }
          
        </Grid>
      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
