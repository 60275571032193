import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import theme from './theme';
import 'typeface-roboto';
import palette from './theme/palette';
import { session } from './helpers/session';

let apiTokenPromise;

const getToken = () => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/auth/token`,
    {
      headers: {
        'access-token': session()
      }
    }
  )
    .then((res) => {
      if (res?.status === 401) {
        throw new Error(res?.statusText)
      }
      return res.json();
    })
    .then((r) => r?.token)
}

const cubejsApi = cubejs(
  async () => {
    if (!apiTokenPromise) {
      apiTokenPromise = await getToken()
        .catch(() => {
          window.localStorage.clear();
          window.location.reload();
        })
    }
    return apiTokenPromise;
  },
  {
    apiUrl: `${process.env.REACT_APP_API_URL}/cubejs-api/v1`,
  }
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '-8px',
    backgroundColor: palette.primary.light,
  },
}));

const AppLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div>{children}</div>
      </div>
    </ThemeProvider>
  );
};

const App = ({ children }) => (
  <CubeProvider cubejsApi={cubejsApi}>
    <AppLayout>{children}</AppLayout>
  </CubeProvider>
);

export default App;
