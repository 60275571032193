import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Main from '../layouts/Main/Main';
import Toolbar from '../components/Toolbar.js';
import Table from '../components/tables/Announcements';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: 15,
  },
}));

const DataTablePage = () => {
  const classes = useStyles();

  const tabs = ['Todos', 'Publicado', 'Não publicado'];
  const [statusFilter, setStatusFilter] = React.useState(0);
  const [startDate, setStartDate] = React.useState(new Date('2019-01-01T00:00:00'));
  const [finishDate, setFinishDate] = React.useState(new Date('2030-01-01T00:00:00'));
  const [priceFilter, setPriceFilter] = React.useState([0, 10000]);
  const [sorting, setSorting] = React.useState(['Announcement.createdAt', 'desc']);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [targetFilter, setTargetFilter] = useState('email');
  const [targetFilterModel, setTargetFilterModel] = useState('Announcement');
  const [targetFilterValue, setTargetFilterValue] = useState(0);

  const targetFilterInputValues = [
    {
      value: 'email',
      label: 'Email',
      model: 'User'
    },
    {
      value: 'id',
      label: 'ID',
      model: 'Announcement'
    },
    {
      value: 'description',
      label: 'Descrição',
      model: 'Announcement'
    },
    {
      value: 'title',
      label: 'Titulo',
      model: 'Announcement'
    },
    {
      value: 'publish',
      label: 'Status',
      model: 'Announcement'
    },
    {
      value: 'reserved',
      label: 'Reservado',
      model: 'Announcement'
    },
  ];

  const makeFilter = () => {
    const filterBase = [{
        dimension: 'Announcement.value',
        operator: 'gt',
        values: [`${priceFilter[0]}`],
      },
      {
        dimension: 'Announcement.value',
        operator: 'lt',
        values: [`${priceFilter[1]}`],
      }
    ]

    if (targetFilterValue) {
      filterBase.push({
        dimension: `${targetFilterModel}.${targetFilter}`,
        operator: 'equals',
        values: [`${targetFilterValue}`]
      })
    }

    return filterBase;
  }

  const query = {
    limit: rowsPerPage,
    offset: rowsPerPage * page,
    order: {
      [`${sorting[0]}`]: sorting[1],
    },
    timeDimensions: [
      {
        dimension: 'Announcement.createdAt',
        dateRange: [startDate, finishDate],
        granularity: null,
      },
    ],
    measures: [
      'Announcement.value',
      'Announcement.discountValue'
    ],
    dimensions: [
      'Announcement.id',
      'User.id',
      'User.email',
      'Announcement.createdAt',
      'Announcement.title',
      'Announcement.description',
      'Announcement.address',
      'Announcement.reserved',
      'Announcement.publish',
    ],
    filters: makeFilter(),
  };

  const countQuery = {
    order: {
      [`${sorting[0]}`]: sorting[1],
    },
    timeDimensions: [
      {
        dimension: 'Announcement.createdAt',
        dateRange: [startDate, finishDate],
        granularity: null,
      },
    ],
    measures: [
      'Announcement.count',
      'Announcement.value'
    ],
  };

  return (
    <Main>
      <div className={classes.root}>
        <Toolbar
          startDate={startDate}
          setStartDate={setStartDate}
          finishDate={finishDate}
          setFinishDate={setFinishDate}
          priceFilter={priceFilter}
          setPriceFilter={setPriceFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          setTargetFilter={setTargetFilter}
          setTargetFilterValue={setTargetFilterValue}
          targetFilterInputValues={targetFilterInputValues}
          setTargetFilterModel={setTargetFilterModel}
          tabs={tabs}
        />
        <div className={classes.content}>
          <Table
            sorting={sorting}
            setSorting={setSorting}
            query={query}
            countQuery={countQuery}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </Main>
  );
};

export default DataTablePage;
