import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useCubeQuery } from '@cubejs-client/react';
import { Grid } from '@material-ui/core';
import Main from '../layouts/Main/Main';
import AnnouncementDetail from '../components/detail/Announcement';
import CircularProgress from '@material-ui/core/CircularProgress';
import KPIChart from '../components/KPIChart';
import AnnouncementReservedTable from '../components/tables/Reserved';
import AnnouncementImages from '../components/AnnouncementImages';
import Map from '../components/Map';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  row: {
    display: 'flex',
    margin: '0 -15px',
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  info: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sales: {
    marginTop: theme.spacing(3),
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const UsersPage = (props) => {
  const [sorting, setSorting] = React.useState(['AnnouncementReserved.createdAt', 'desc']);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const classes = useStyles();
  let { id } = useParams();
  
  const query = {
    measures: [
      'Announcement.count',
      'Announcement.value',
      'Announcement.discount',
      'Announcement.discountValue',
    ],
    timeDimensions: [
      {
        dimension: 'Announcement.createdAt',
      },
      {
        dimension: 'Announcement.reserved',
      },
    ],
    dimensions: [
      'Announcement.id',
      'User.id',
      'User.email',
      'Announcement.createdAt',
      'Announcement.title',
      'Announcement.description',
      'Announcement.address',
      'Announcement.reserved',
      'Announcement.publish',
      'Announcement.spaceResources',
      'Announcement.accessType',
      'Announcement.accessDay',
      'Announcement.spaceType',
      'Announcement.spaceSize',
    ],
    filters: [
      {
        dimension: 'Announcement.id',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  };

  const queryTableAnnouncementReserved = {
    measures: [
      'AnnouncementReserved.count'
    ],
    dimensions: [
      'Announcement.id',
      'AnnouncementReserved.id',
      'AnnouncementReserved.status',
      'AnnouncementReserved.descriptionGuest',
      'AnnouncementReserved.descriptionGuardian',
      'AnnouncementReserved.daysRented',
      'Announcement.id',
      'AnnouncementReserved.createdAt',
      'User.email',
      'User.id'
    ],
    filters: [
      {
        dimension: 'Announcement.id',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  }
  
  const cards = [
    {
      title: 'Total de Interessados',
      query: {
        measures: [
          'AnnouncementReserved.count'
        ],
        filters: [
          {
            dimension: 'Announcement.id',
            operator: 'equals',
            values: [`${id}`],
          },
        ],
      },
      duration: 1.25,
    },
    {
      title: 'Total de cancelados pelo Guardião',
      query: {
        measures: [
          'AnnouncementReserved.count'
        ],
        filters: [
          {
            dimension: 'Announcement.id',
            operator: 'equals',
            values: [`${id}`],
          },
          {
            dimension: 'AnnouncementReserved.status',
            operator: 'equals',
            values: ['REPROVED_GUARDIAN'],
          },
        ],
      },
      duration: 1.25,
    },
    {
      title: 'Total de cancelados pelo Anfitrião',
      query: {
        measures: [
          'AnnouncementReserved.count',
        ],
        filters: [
          {
            dimension: 'Announcement.id',
            operator: 'equals',
            values: [`${id}`],
          },
          {
            dimension: 'AnnouncementReserved.status',
            operator: 'equals',
            values: ['REPROVED_GUEST'],
          },
        ],
      },
      duration: 1.25,
    },
  ];

  const countQuery = {
    timeDimensions: [
      {
        dimension: 'AnnouncementReserved.createdAt',
        granularity: null,
      },
    ],
    measures: [
      'AnnouncementReserved.count',
    ],
  };
  
  const { resultSet, error, isLoading } = useCubeQuery(query);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (!resultSet) {
    return null;
  }
  if (resultSet) {
    let data = resultSet.tablePivot();
    let announcementData = data[0] || [];
    return (
      <Main>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <div className={classes.row}>
                {cards?.map((item, index) => {
                  return (
                    <Grid className={classes.info} key={item.title + index} item lg={6} sm={6} xl={6} xs={12}>
                      <KPIChart {...item} />
                    </Grid>
                  );
                })}
              </div>
            </Grid>
            <Grid item lg={5} sm={12} xl={12} xs={12}>
              <AnnouncementDetail
                data={announcementData}
              />
            </Grid>
            <Grid item lg={7} sm={12} xl={12} xs={12}>
              <AnnouncementImages />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <AnnouncementReservedTable
                query={queryTableAnnouncementReserved}
                countQuery={cards[0].query}
                sorting={sorting}
                setSorting={setSorting}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                setPage={setPage}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Map query={query}></Map>
            </Grid>
          </Grid>
        </div>
      </Main>
    );
  }
};

export default UsersPage;
