import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useCubeQuery } from '@cubejs-client/react';
import { Grid } from '@material-ui/core';
import Main from '../layouts/Main/Main';
import ReservedDetail from '../components/detail/Reserved';
import ReservedEventTable from '../components/tables/ReservedEvent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chat from '../components/Chat';
import ReservedImage from '../components/ReservedImage';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  row: {
    display: 'flex',
    margin: '0 -15px',
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  info: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sales: {
    marginTop: theme.spacing(3),
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const UsersPage = (props) => {
  const [sorting, setSorting] = React.useState(['AnnouncementReserved.createdAt', 'desc']);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const classes = useStyles();
  
   let { id } = useParams();

   const query = {
    timeDimensions: [
      {
        dimension: 'AnnouncementReserved.createdAt',
        granularity: null,
      },
    ],
    measures: [
      'AnnouncementReserved.count',
    ],
    dimensions: [
      'AnnouncementReserved.id',
      'AnnouncementReserved.status',
      'AnnouncementReserved.descriptionGuest',
      'AnnouncementReserved.descriptionGuardian',
      'AnnouncementReserved.daysRented',
      'AnnouncementReserved.chat',
      'Announcement.id',
      'User.id',
      'User.email',
      'AnnouncementReserved.createdAt',
      'AnnouncementReserved.fileName'
    ],
    filters: [
      {
        dimension: 'AnnouncementReserved.id',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  };

  const queryReservedEvent = {
    dimensions: [
      'ReservedEvent.id',
      'ReservedEvent.createdAt',
      'ReservedEvent.type',
      'ReservedEvent.metadata',
      'ReservedEvent.announcementReservedId',
      'User.id',
      'User.email',
    ],
    filters: [
      {
        dimension: 'ReservedEvent.announcementReservedId',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  };

  const queryCountReservedEvent = {
    measures: [
      'ReservedEvent.count',
    ],
    filters: [
      {
        dimension: 'ReservedEvent.announcementReservedId',
        operator: 'equals',
        values: [`${id}`],
      },
    ],
  };
  
  const { resultSet, error, isLoading } = useCubeQuery(query);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (!resultSet) {
    return null;
  }
  if (resultSet) {
    let data = resultSet.tablePivot();
    
    let reservedData = data[0] || [];

    const chat = reservedData['AnnouncementReserved.chat'] || [];

    const owner = reservedData['User.id'];

    const announcementId = reservedData['Announcement.id'];

    return (
      <Main>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item lg={7} sm={12} xl={12} xs={12}>
              <ReservedDetail
                reservedData={reservedData}
              />
            </Grid>
            <Grid item lg={5} sm={12} xl={12} xs={12}>
              <ReservedImage
                fileName={reservedData['AnnouncementReserved.fileName'] || 'not-found'}
              />
            </Grid>
            <Grid className={classes.info} item lg={12} sm={12} xl={12} xs={12} spacing={2}>
              <ReservedEventTable
                query={queryReservedEvent}
                countQuery={queryCountReservedEvent}
                sorting={sorting}
                setSorting={setSorting}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                setPage={setPage}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Chat chat={chat} owner={owner} announcementId={announcementId}></Chat>
            </Grid>
          </Grid>
        </div>
      </Main>
    );
  }
};

export default UsersPage;
