import React from 'react';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Redirect } from 'react-router-dom';
import awsConfig from '../config/aws-exports';

Amplify.configure(awsConfig);

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);

  if (authState === AuthState?.SignedIn && user) {
    const keyAccess = `${user?.keyPrefix}.${user?.username}`;
    window.localStorage.setItem('keyAccess', keyAccess);
  } else {
    window.localStorage.removeItem('keyAccess');
  }

  return authState === AuthState?.SignedIn && user ? (
    <Redirect to="/" />
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn
        hideSignUp
        headerText="Bem vindo a Stokado"
        submitButtonText="Entrar"
        formFields={[
          {
            type: "email",
            label: "Email",
            placeholder: "Email",
            inputProps: {
              required: true,
              autocomplete: "username"
            },
          },
          {
            type: "password",
            label: "Senha",
            placeholder: "Senha",
            inputProps: {
              required: true,
              autocomplete: "new-password"
            },
          }
        ]} 
        slot="sign-in"
      ></AmplifySignIn>
    </AmplifyAuthenticator>
  );
}

export default AuthStateApp;