import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { useCubeQuery } from '@cubejs-client/react';
import { useParams } from 'react-router-dom';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles({
  media: {
    height: 340,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  const { fileName } = props;

  if (fileName) {
    return (
      <Grid item lg={12} sm={12} xl={12} xs={12} container spacing={2}>
       <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={`https://stokado-prod-anncmt-images-bucket-20211125014153574900000007.s3.us-west-2.amazonaws.com/${fileName}`}
                title="Contemplative Reptile"
                onClick={e => window.open(`https://stokado-prod-anncmt-images-bucket-20211125014153574900000007.s3.us-west-2.amazonaws.com/${fileName}`,'_blank')}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  }
  
}